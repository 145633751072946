import React from 'react'

import { Badge, Badges } from '@te-digi/styleguide'

const BadgeExample2 = () => (
  <Badges noMargin>
    <Badge
      color="white"
      bordered
    >
      Bordered white badge
    </Badge>
    <Badge
      color="white"
      borderColor="primary"
    >
      Primary
    </Badge>
    <Badge
      color="white"
      borderColor="secondary"
    >
      Secondary
    </Badge>
    <Badge
      color="white"
      borderColor="success"
    >
      Success
    </Badge>
    <Badge
      disabled
      color="white"
      bordered
    >
      Disabled
    </Badge>
  </Badges>
)

export { BadgeExample2 }
