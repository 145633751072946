import React from 'react'

import {
  Badge,
  BadgeDivider,
  Badges,
  BulletFillIcon,
  Col,
  GridList,
  Inline,
  Paragraph,
  Row,
  Spacer,
  Stack,
  Text
} from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { LinkMock } from '../../components/LinkMock'
import { Section } from '../../components/Section'
import { BadgeExample1 } from '../../examples/react/BadgeExample1'
import { BadgeExample2 } from '../../examples/react/BadgeExample2'
import { BadgeExample3 } from '../../examples/react/BadgeExample3'
import IconHTMLExample from '../../examples/html/IconHTMLExample'

const Page = () => (
  <Content
    heading="Badge"
    components={[
      { component: Badge, restElement: true },
      { component: BadgeDivider, restElement: true },
      { component: Badges, restElement: true }
    ]}
    status={[{ type: 'accessible', version: '13.10.0' }]}
  >
    <Section>
      <Playground example={BadgeExample1} />
      <Playground example={BadgeExample2} />

      <Playground example={BadgeExample3} />

      <Playground>
        <Badges noMargin>
          <Badge
            as={LinkMock}
            to="#"
          >
            With link
          </Badge>
          <Badge iconLeft={<BulletFillIcon color="success" />}>
            With bullet
          </Badge>
        </Badges>
      </Playground>
      <Playground>
        <GridList
          gap="sm"
          md={2}
          noMargin
        >
          <Badge block>
            <Row gap="xs">
              <Col>Lorem ipsum dolor</Col>
              <Col xs="auto">
                <BadgeDivider />
              </Col>
              <Col xs="auto">5v 7kk</Col>
            </Row>
          </Badge>
          <Badge block>
            <Row gap="xs">
              <Col>Lorem ipsum dolor sit amet</Col>
              <Col xs="auto">
                <BadgeDivider />
              </Col>
              <Col xs="auto">1v 1kk</Col>
            </Row>
          </Badge>
          <Badge block>
            <Row gap="xs">
              <Col>Lorem ipsum</Col>
              <Col xs="auto">
                <BadgeDivider />
              </Col>
              <Col xs="auto">2kk</Col>
            </Row>
          </Badge>
        </GridList>
      </Playground>
      <Playground>
        <Badges noMargin>
          <Badge>
            <Text lines={2}>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta,
              architecto rem quidem, neque sequi nesciunt nisi veniam enim,
              tenetur debitis cupiditate deleniti. Voluptatem sint voluptatibus
              nemo repellendus, alias accusamus. Repudiandae iste ratione eos
              sunt, blanditiis saepe incidunt cum deserunt nesciunt maiores
              beatae qui quidem porro quos doloribus itaque tenetur laudantium.
            </Text>
          </Badge>
        </Badges>
      </Playground>
      <Playground isDark>
        <Badges noMargin>
          <Badge color="white">Lorem ipsum</Badge>
        </Badges>
      </Playground>
      <Playground>
        <Stack gap="sm">
          <Badge>
            <Inline gap="sm">
              suomi
              <BadgeDivider />
              <Text>äidinkieli</Text>
            </Inline>
          </Badge>
          <>
            <Badge>
              <Inline gap="sm">
                ruotsi
                <BadgeDivider />
                <Text>erittäin hyvä</Text>
              </Inline>
            </Badge>
            <Spacer
              marginLeft="xs"
              marginTop="xxs"
            >
              <Paragraph noMargin>
                Olen suorittanut tasokursseja yli 10 vuotta sitten, mutta
                kirjallinen taito on päässyt ruostumaan.
              </Paragraph>
            </Spacer>
          </>
          <Badge>
            <Inline gap="sm">
              englanti
              <BadgeDivider />
              <Text>alkeet</Text>
            </Inline>
          </Badge>
        </Stack>
      </Playground>
      <Playground format="html">
        <ul className="badges mb-0">
          <li className="badges-item">
            <span className="badge">Lorem ipsum</span>
          </li>
          <li className="badges-item">
            <span className="badge badge-primary">Lorem ipsum</span>
          </li>
          <li className="badges-item">
            <LinkMock className="badge badge-secondary">Lorem ipsum</LinkMock>
          </li>
          <li className="badges-item">
            <span className="badge">
              <IconHTMLExample
                className="badge-icon"
                color="success"
                name="BulletFillIcon"
              />
              Lorem ipsum
            </span>
          </li>
          <li className="badges-item">
            <span className="badge badge-bordered">Lorem ipsum</span>
          </li>
          <li className="badges-item">
            <span className="badge disabled">Lorem ipsum</span>
          </li>
        </ul>
      </Playground>
    </Section>
    <Section title="Määrän rajoittaminen">
      <Paragraph>
        Asettamalla maksimin näkyvissä oleville <Code>Badge</Code>
        -komponenteille, näytetään listalla ylimenevien sijaan niiden lukumäärä.
      </Paragraph>
      <Playground>
        <Badges
          max={3}
          noMargin
        >
          <Badge>Lorem</Badge>
          <Badge>Ipsum</Badge>
          <Badge>Dolor</Badge>
          <Badge>Sit</Badge>
          <Badge>Amet</Badge>
        </Badges>
      </Playground>
    </Section>
    <Section title="Saavutettavuus">
      <Paragraph>
        <Code>Badge</Code> ei tuota html-koodiin semantiikkaa, eli se ei
        ruudunlukijalla luettaessa korostu leipätekstistä. Varmista, että{' '}
        <Code>Badge</Code>n label kuvaa sen tarkoitusta.
      </Paragraph>
      <Paragraph>
        Style Guiden esimerkeissä olevat väriyhdistelmät on kontrastitestattu,
        mutta esimerkiksi harmaan tekstin kontrasti harmaalla pohjalla ei ole
        riittävä.
      </Paragraph>
    </Section>
  </Content>
)

export default Page
