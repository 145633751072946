import React from 'react'

import {
  AlertIcon,
  Badge,
  Badges,
  CancelIcon,
  CheckIcon
} from '@te-digi/styleguide'

const BadgeExample3 = () => (
  <>
    <Badges>
      <Badge
        color="success"
        iconLeft={<CheckIcon />}
      >
        Toteutettu
      </Badge>
      <Badge
        color="secondary"
        iconLeft={<AlertIcon />}
      >
        Ei toteutettu
      </Badge>
      <Badge iconLeft={<CancelIcon />}>Ei voimassa</Badge>
    </Badges>

    <Badges noMargin>
      <Badge
        color="white"
        borderColor="success"
        iconLeft={<CheckIcon />}
      >
        Toteutettu
      </Badge>

      <Badge
        color="white"
        borderColor="secondary"
        iconLeft={<AlertIcon />}
      >
        Ei toteutettu
      </Badge>

      <Badge
        color="white"
        bordered
        iconLeft={<CancelIcon />}
      >
        Ei voimassa
      </Badge>
    </Badges>
  </>
)

export { BadgeExample3 }
