import React from 'react'

import { Badge, Badges } from '@te-digi/styleguide'

const BadgeExample1 = () => (
  <Badges noMargin>
    <Badge>Default badge</Badge>
    <Badge color="primary">Primary</Badge>
    <Badge color="secondary">Secondary</Badge>
    <Badge color="success">Success</Badge>
    <Badge bordered>Bordered</Badge>
    <Badge disabled>Disabled</Badge>
  </Badges>
)

export { BadgeExample1 }
